<template>
    <div class="mainContainer">
        <div class="headerlogin d-flex flex-column align-items-center my-4">
            <img class="imgLogo" src="../assets/letrasLogo.png" alt="logo">
            <h1 class="display-6 mb-4">TimeSheet</h1>
        </div>
        <Authenticator :form-fields="formFields">
        </Authenticator>  
    </div>

  
</template>

<script setup>

import {Authenticator} from '@aws-amplify/ui-vue'
import { I18n } from 'aws-amplify'
import { translations } from '@aws-amplify/ui-vue'
import router from '../router/index'

import { Hub, Logger } from 'aws-amplify'


import { onMounted } from 'vue'
import store from '../store/index'

const formFields = {
    signUp: {     
      name: {
        order: 1
      },
      preferred_username: {
        order: 2
      },
      email: {
        order: 3
      },
      password: {
        order: 4
      },
      confirm_password: {
        order: 5
      }
    },
}

const logger = new Logger('My-Logger');

const listener = (data) => {
  switch (data.payload.event) {
    case 'signIn':
      store.userIdSetValue(data.payload.data.attributes['custom:srk_id'])
      logger.info('user signed in');
      router.push('/');
      break;
    case 'signOut':
      logger.info('user signed out');
      break;
  }
}

Hub.listen('auth', listener);

I18n.putVocabularies(translations);
I18n.setLanguage('es');

I18n.putVocabularies({
  es: {
    'Enter your Email': 'Ingrese su mail (@srk.com.ar)',
    'Enter your Password':'Ingrese su Contraseña',
    'Name': 'Nombre Completo',
    'Enter your Name':'Ej: GUTIERREZ, Juan Pablo',
    'Preferred Username':'Identificación de Usuario',
    'Enter your Preferred Username':'Usuario de mail de SRK (sin @srk.com.ar)',
    'Please confirm your Password':'Confirme su Contraseña',
    'Password must have at least 8 characters':'La Contraseña debe tener al menos 8 caracteres',
    'Reset Password':'Cambiar Contraseña',
  }
})

onMounted(() => {
  console.log('Auth Mounted. ALl null')
  store.usuarioSetValue(null)
  store.nombreSetValue(null)
  store.userIdSetValue(null)
})

</script>

<style>
[data-amplify-authenticator] {
  --amplify-font-family: 'Poppins';
  --amplify-components-tabs-item-active-color: #F37226;
  --amplify-components-tabs-item-focus-color: #F37226;
  --amplify-components-tabs-item-active-border-color: #F37226;
}

.amplify-button[data-variation='primary'] {
  background: linear-gradient(
    to right,
    rgb(243,112,32,100%),
    rgb(243,112,32,80%)
  );
}

.amplify-tabs-item[data-state=active] {
    border-color: orange;
    font-size: 1.4rem;
}

.imgLogo {
    margin-top: 50px;
    margin-bottom: 20px;
    max-width: 164px;
    max-height: 44px;
}

.mainContainer {
    height: 100vh;
    background-color: #D6D4D0;
    display: flex;
    flex-direction: column;
    align-items: center;

}
</style>