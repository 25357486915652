import 'devextreme/dist/css/dx.light.css';
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import {Amplify} from 'aws-amplify'
import awsConfig from './aws-exports'
import "@aws-amplify/ui-vue/styles.css"

Amplify.configure(awsConfig);

const app = createApp(App)

app.use(router)

app.mount('#app')