import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../components/HomeView.vue'
import AuthPage from '../components/AuthView.vue'
import {Auth} from 'aws-amplify'
import store from '../store/index.js'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/:fecha?',
      name: 'home',
      component: HomeView,
      meta: { 
        auth: true,
        reload: true }
    },
    {
      path: '/auth',
      component: AuthPage
    },
    {
      path: '/about',
      name: 'about',
      meta: { 
        auth: true,
        reload: true },
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../components/AboutView.vue')
    },
    {
      path: '/calendario',
      name: 'calendario',
      meta: { 
        auth: true,
        reload: true },
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../components/CalendarView.vue')
    },
    {
      path: '/resumen',
      name: 'resumen',
      meta: { 
        auth: true,
        reload: true },
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../components/ResumenView.vue')
    },
    {
      path: '/config',
      name: 'config',
      meta: { 
        auth: true,
        reload: true },
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../components/ConfigView.vue')
    },
    {
      path: '/semana',
      name: 'semana',
      meta: { 
        auth: true,
        reload: true },
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../components/SemanaView.vue')
    },
    {
      path: '/dona',
      name: 'dona',
      meta: { 
        auth: true,
        reload: true },
      // route level code-splitting
      // this generates a separate chunk (About.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import('../components/DonaView.vue')
    },
  ]
})

const baseUrl = "https://iylotton6j.execute-api.sa-east-1.amazonaws.com/Desarrollo/getuserid?cognito="

// router.beforeResolve( (to, from, next) => {
//   if(to.meta.auth) {
//     Auth.currentAuthenticatedUser()
//     .then((user) => {
//       store.usuarioSetValue(user.username)
//       store.nombreSetValue(user.attributes.preferred_username)
//       fetch(baseUrl + user.username, {
//         method: 'GET'
//         })
//         .then(resp => resp.json())
//         .then(data => store.userIdSetValue(data[0].us_Id))
//     })
//     .then(() => { next() })
//     .catch(() => {
//       next({
//         path: '/auth'
//       })
//     })
//   } else {
//     next()
//   }
// })

router.beforeResolve(async (to, from, next) => {

  if(to.meta.auth) {
    await Auth.currentAuthenticatedUser()
    .then(async (user) => {
      if(store.role == '') {
        store.usuarioSetValue(user.username)
        store.nombreSetValue(user.attributes.preferred_username)
        if (!user.attributes['custom:srk_id'])
          await fetch(baseUrl + user.username)
            .then(resp => resp.json())
            .then(data =>{ 
              store.userIdSetValue(data[0].us_Id)
              if (data[0].us_IsAdmin == 1)
                store.userSetRole('admin')

              Auth.updateUserAttributes(user, { 'custom:srk_id': data[0].us_Id.toString() } )
                .then(result =>{ console.log(result)})
                .catch(err => console.log(err))

            })
        else {

          store.userIdSetValue(user.attributes['custom:srk_id'])
          
          await fetch(baseUrl + user.username)
            .then(resp => resp.json())
            .then(data =>{ 
              if (data[0].us_IsAdmin == 1)
                store.userSetRole('admin')
              else
                store.userSetRole('none')
          })

        }

      }
    })
    .then(() => { next() })
    .catch(() => {
      console.log('No esta logeado ....')
      next({ path: '/auth' })
    })
  } else {
    next()
  }
})



export default router
