import { reactive } from 'vue'

const store = reactive({
    usuario: '',
    nombre: '',
    userId: null,
    selTareas: [],
    copyTareas: [],
    rowExpanding: [],
    isExpanding: 0,
    rowMode: 0,
    timeInterval: 30,
    timeTipo: 0,
    confirmDelete: 1,
    repiteTarea: 0,
    title: 'Timesheet',
    role: '',
    usuarioSetValue(newValue) {
        this.usuario = newValue
    },
    nombreSetValue(newValue) {
        this.nombre = newValue
    },
    selTareasSetValue(newValue) {
        this.selTareas = newValue
    },
    copyTareasSetValue(newValue) {
        this.copyTareas = newValue
    },
    rowExpandingSetValue(newValue) {
        this.rowExpanding = newValue
    },
    isExpandingSwitch(newValue) {
        this.isExpanding = newValue
    },
    userIdSetValue(newValue) {
        this.userId = newValue
    },
    userRowModeSetValue(newValue) {
        this.rowMode = newValue
    },
    userTimeIntervalSetValue(newValue) {
        this.timeInterval = newValue
    },
    userTimeTipoSetValue(newValue) {
        this.timeTipo = newValue
    },
    userConfirmDeleteSetValue(newValue) {
        this.confirmDelete = newValue
    },
    userRepiteTareaSetValue(newValue) {
        this.repiteTarea = newValue
    },
    titleSetValue(newValue) {
        this.title = newValue
    },
    userSetRole(newValue) {
        this.role = newValue
    }
})

export default store