<template>
  <div class="mantenimiento" v-if="mantenimiento">
    <h1>We&rsquo;ll be back soon!</h1>
    <div>
        <p>Sorry for the inconvenience but we&rsquo;re performing some maintenance at the moment. If you need to you can always <a href="mailto:ewitte@srk.com.ar">contact us</a>, otherwise we&rsquo;ll be back online shortly!</p>
        <p>&mdash;appSRK Developer Team</p>
    </div>
  </div>
  <div class="main d-flex flex-column align-items-center" v-if="!mantenimiento">

    <div class="cuerpocabecera w-75">
      <Status />
    </div>
    <div class="cuerpocuerpo flex-grow-1 w-75 overflow-auto">
      <router-view></router-view>
    </div>
    <div class="cuerpopie w-75">
      <FooterView />
    </div>
</div>

</template>
 
<script setup>
import Status from './components/StatusView.vue'
import FooterView from './components/FooterView.vue'
// import { Auth } from 'aws-amplify'
// import store from './store/index'

import { onMounted } from 'vue'

 
const mantenimiento = false
// const baseUrl = "https://iylotton6j.execute-api.sa-east-1.amazonaws.com/Desarrollo/getuserid?cognito="

// function handleErrors(response) {
//     if (!response.ok) {
//         throw Error(response.statusText);
//     }
//     return response;
// }

// Auth.currentAuthenticatedUser()
//   .then(async (user) => {
//     store.usuarioSetValue(user.username)
//     store.nombreSetValue(user.attributes.preferred_username)
//     return await fetch(baseUrl + user.username, {
//       method: 'GET'
//       })
//       .then(handleErrors)
//       .then(resp => resp.json())
//       .then(data => { 
//         console.log('Autentico ... ',data[0])
//         store.userIdSetValue(data[0].us_Id)
//         store.userRowModeSetValue(data[0].us_ts_RowMode)
//         store.userTimeIntervalSetValue(data[0].us_ts_TimeInterval)
//         store.userTimeTipoSetValue(data[0].us_ts_TimeTipo)
//         store.userConfirmDeleteSetValue(data[0].us_ts_ConfirmDelete)
//       })

//     })
//   .catch(() => { })

//---- Localization
import esMessages from './diccionario/es.json'
import { locale, loadMessages } from "devextreme/localization";

onMounted(() => {
  loadMessages(esMessages)
  locale(navigator.language)
});


</script>

<style>


html {
  margin: 0;
}

body {
  font-family: 'Poppins', sans-serif;
}

.contenedor {
  min-width: 600px;
  height: 100vh;
  margin: auto;
}


.status {
  min-height: 70px;
  color:white;
}

.cuerpo {
  height: 100%;
}

.footer {
  min-height: 70px;
  color:white;
}

/*  */

.main {
  height: 100svh;
}

.cuerpocabecera {
  min-height: 50px;
}

.cuerpopie {
  min-height: 50px;
}

.texto {
  width: 100%;
}
</style>