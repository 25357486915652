<template>
    <div class="contenedorMenu p-2 m-2" >
        <ul class="list-group d-flex flex-row justify-content-center">
            <li class="list-group-item list-group-item-action text-center border-0 bg-transparent" 
                :class="{ 'active' : selected === 0 }" @click="selected = 0">
                <RouterLink to="/" class="text-decoration-none text-white">
                    <span data-message="Horas Diarias">
                        <i class="bi bi-folder"></i>
                    </span>
                </RouterLink>
            </li>
            <li class="list-group-item list-group-item-action text-center border-0 bg-transparent"
                :class="{ 'active' : selected === 1 }" @click="selected = 1">
                <RouterLink to="/calendario" class="text-decoration-none text-white">
                    <span data-message="Calendario">
                        <i class="bi bi-calendar4-week"></i>                    </span>
                </RouterLink>
            </li>
            <li class="list-group-item list-group-item-action text-center border-0 bg-transparent"
                :class="{ 'active' : selected === 2 }" @click="selected = 2">
                <RouterLink to="/resumen" class="text-decoration-none text-white">
                    <span data-message="Gráfico">
                        <i class="bi bi-bar-chart"></i>
                    </span>
                </RouterLink>
            </li>
            <li class="list-group-item list-group-item-action text-center border-0 bg-transparent"
                :class="{ 'active' : selected === 3 }" @click="selected = 3">
                <RouterLink to="/semana" class="text-decoration-none text-white">
                    <span data-message="Semana">
                        <i class="bi bi-calendar-range"></i>
                    </span>
                </RouterLink>
            </li>
            <li class="list-group-item list-group-item-action text-center border-0 bg-transparent"
                :class="{ 'active' : selected === 4 }" @click="selected = 4">
                <RouterLink to="/config" class="text-decoration-none text-white">
                    <span data-message="Configuración">
                        <i class="bi bi-gear"></i>
                    </span>
                </RouterLink>
            </li>
            <li class="list-group-item list-group-item-action text-center border-0 bg-transparent">
                <a href="#" class="text-decoration-none text-white" @click="desloguear">
                    <span data-message="Salir">
                        <i class="bi bi-box-arrow-right"></i>
                    </span>

                </a>
            </li>
        </ul>
    </div>
</template>

<script setup>
import { Auth } from 'aws-amplify'
import router from '../router'
import { ref } from 'vue'

const selected = ref(0)

function desloguear() {
    Auth.signOut()
    router.push('/auth')
    Auth.currentAuthenticatedUser().then(() => {
        console.log('Usuario NO logueado !!!')
    })

}

</script>

<style>

.contenedorMenu {
    background-color: rgb(243,112,32);
    border-radius: 5px;
}

.list-group-item-action i {
    color: rgb(245, 206, 161);
}

.list-group-item-action i:hover {
    color: white;
    font-size: 1.3rem;
}

.list-group-item-action.active:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  height: 1px;
  width: 30%;
  border-bottom:1px solid white;
}

.list-group-item-action.active i {
    color: white;
    font-size: 1.3rem;
}


[data-message] {
  position: relative;
}

[data-message]:hover:after {
  opacity: 1;
  transition: all .3s ease;
  bottom: 25px;
  left: 0;
}

[data-message]:after {
  content: attr(data-message);
  position: absolute;
  left: 0;
  bottom: 20px;
  font-size: .8em;
  opacity: 0;
  padding: 5px 10px;
  background: rgba(44, 44, 52, 0.8);
  border-radius: 5px;
  transition: all .3s ease;
  white-space: nowrap;
  color: #fff;
}

[data-message]:before {
  height: 0;
  width: 0;
  content: '';
  border-left: 5px solid transparent;
  border-right: 5px solid transparent; 
  border-top: 5px solid rgba(0,0,0, .8);
  position: absolute;
  left: 5px;
  opacity: 0;
  bottom: 15px;
  transition: all .3s ease;
}

[data-message]:hover:before {
  opacity: 1;
  bottom: 20px;
  transition: all .3s ease;
}


</style>